import { Alert, Box, Grid, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

const Page = () => {
    const navigate = useNavigate();

    setTimeout(() => {
        navigate("/");
    }, 3000);

    return (
        <Box
            className='home__form'
            sx={{
                width: 500,
                maxWidth: "100%",
            }}>
            <Grid container rowSpacing={2}>
                <Grid item xs={12}>
                    <Typography variant='h5' sx={{ marginY: { xs: 2, lg: 4 }, textAlign: "center" }}>
                        Logout Successful!!!
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Alert severity='success'>You have been successfully logout. Please wait while we redirect you to login page.</Alert>
                </Grid>
                <Grid item xs={12} sx={{ textAlign: "center" }} className='mt-4'>
                    <Link to='/'>
                        <KeyboardArrowLeftIcon />
                        Back to Login Page
                    </Link>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Page;
