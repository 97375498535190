import { Alert, Box, Grid, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import Input from "@form/Input";
import Button from "@form/Button";
import { useContext, useState } from "react";
import DataContext from "@/context/context";
import apiRequest from "@/utils/api";

const Page = () => {
    const navigate = useNavigate();
    const { setUserData } = useContext(DataContext);
    const [message, setMessage] = useState("");
    const [type, setType] = useState("info");

    const { control, handleSubmit } = useForm({
        defaultValues: {
            email: "",
            password: "",
        },
    });
    const onSubmit = async (data) => {
        setType("info");
        setMessage("Checking login details....");
        const response = await apiRequest("post", "/common/login", data);
        const response_user = response.data.user;
        setUserData({
            ...response_user,
            logged: true,
        });

        switch (response_user.type) {
            case "ADMIN":
                navigate("./admin/");
                break;
            case "HOSPITAL":
                navigate("./hospital/");
                break;
            case "DOCTOR":
                navigate("./doctor/");
                break;
            default:
                break;
        }
    };

    return (
        <Box component='form' onSubmit={handleSubmit(onSubmit)} className='home__form' noValidate autoComplete='off'>
            <Grid container rowSpacing={2}>
                <Grid item xs={12}>
                    <Typography variant='h5' sx={{ marginY: { xs: 2, lg: 4 }, textAlign: "center" }}>
                        Login to the Application
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name='email'
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: "Please enter Email ID!",
                            },
                            minLength: {
                                value: 10,
                                message: "Email cannot be less than 10 characters",
                            },
                            pattern: {
                                value: /^\S+@\S+$/i,
                                message: "Please enter valid Email ID!",
                            },
                        }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => <Input fullWidth label='Email' type='email' placeholder='Enter Email ID' id='email' value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name='password'
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: "Please enter Password!",
                            },
                            minLength: {
                                value: 10,
                                message: "Password cannot be less than 10 characters",
                            },
                        }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => <Input fullWidth label='Password' type='password' placeholder='Enter Password' id='password' value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />}
                    />
                </Grid>
                {message && (
                    <Grid item xs={12}>
                        <Alert severity={type}>{message}</Alert>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Button fullWidth variant='contained' type='submit' size='large'>
                        Login
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Link to='../forgot-password'>Forgot Password?</Link>
                        </Grid>
                        <Grid item xs={12}>
                            <hr />
                        </Grid>
                        <Grid item xs={6}>
                            <Link to='../register-hospital'>Hospital Registration</Link>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: { sm: "right" } }}>
                            <Link to='../register-doctor'>Doctor Registration</Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Page;
