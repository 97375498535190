import React from "react";

const Pages = {
    Dashboard: React.lazy(() => import("./Dashboard")),
    Home: React.lazy(() => import("./Home")),
    Branches: React.lazy(() => import("./Branches")),
    Hospitals: React.lazy(() => import("./Hospitals")),
    Services: React.lazy(() => import("./Services")),
    Specialities: React.lazy(() => import("./Specialities")),
    Types: React.lazy(() => import("./Types")),
};

export default Pages;
