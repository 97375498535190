import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

const Page = () => {
    return (
        <div className='home'>
            <div className='home__column home__column--one'>
                <img src={require("../img/logo-light.png")} alt='Svastha Circle' />
            </div>
            <div className='home__column home__column--two'>
                <div className='home__content'>
                    <Box
                        sx={{
                            width: 800,
                            maxWidth: "100%",
                        }}>
                        <Outlet />
                    </Box>
                </div>

                <footer>&copy; Copyright 2023-24</footer>
            </div>
        </div>
    );
};

export default Page;
