import Home from "./Home";
import About from "./About";
import Login from "./Login";
import Logout from "./Logout";
import RegisterHospital from "./RegisterHospital";
import RegisterDoctor from "./RegisterDoctor";
import Admin from "./admin";
import Hospital from "./hospital";
import Doctor from "./doctor";

const Pages = {
    Home: Home,
    About: About,
    Login: Login,
    Logout: Logout,
    RegisterHospital: RegisterHospital,
    RegisterDoctor: RegisterDoctor,
    Admin: Admin,
    Hospital: Hospital,
    Doctor: Doctor,
};

export default Pages;
