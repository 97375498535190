import { Autocomplete, Box, Grid, Typography } from "@mui/material";
import { Step, Stepper } from "react-form-stepper";
import Input from "@form/Input";
import Button from "@form/Button";
import { Controller, useForm } from "react-hook-form";
import { useContext, useEffect, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import "dayjs/locale/en-in";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import apiRequest from "../utils/api";
import DataContext from "../context/context";
import Loader from "@components/Loader";

const Page = () => {
    const [show, setShow] = useState(true);
    const [active, setActive] = useState(0);
    const [complete, setComplete] = useState(0);
    const [natureData, setNatureData] = useState([{ id: "other", label: "Other" }]);
    const [servicesData, setServicesData] = useState([{ id: "other", label: "Other" }]);
    const [specialityData, setSpecialityData] = useState([{ id: "other", label: "Other" }]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [formData, setFormData] = useState();
    const { apiErrorHandler } = useContext(DataContext);
    const navigate = useNavigate();
    const { control, handleSubmit } = useForm({
        defaultValues: {
            name: "",
            nature: "",
            registration_number: "",
            registration_date: dayjs(),
            services: "",
            services_other: "",
            speciality: "",
            speciality_other: "",
            nurses: 1,
            doctors: 1,
            address1: "",
            address2: "",
            state: "",
            city: "",
            pin_code: "",
            email_01: "",
            email_02: "",
            phone_01: "",
            phone_02: "",
            mobile_01: "",
            mobile_02: "",
            total_beds: 0,
            icu_beds: 0,
            private_beds: 0,
            semi_private_beds: 0,
            general_beds: 0,
            status: 0,
        },
    });
    const onSubmit = async (data) => {
        nextStep(data);
    };

    useEffect(() => {
        const promise_type = new Promise((resolve, reject) => {
            apiRequest("get", "/common/hospital/types").then((response) => {
                if (response && response.data) {
                    if (response.data.success) {
                        setNatureData(response.data.data);
                        resolve(true);
                    } else {
                        reject(response.data.error);
                    }
                }
                reject(response);
            });
        });

        const promise_services = new Promise((resolve, reject) => {
            apiRequest("get", "/common/hospital/services").then((response) => {
                if (response && response.data) {
                    if (response.data.success) {
                        setServicesData(response.data.data);
                        resolve(true);
                    } else {
                        reject(response.data.error);
                    }
                }
                reject(response);
            });
        });

        const promise_specialities = new Promise((resolve, reject) => {
            return apiRequest("get", "/common/hospital/specialities").then((response) => {
                if (response && response.data) {
                    if (response.data.success) {
                        setSpecialityData(response.data.data);
                        resolve(true);
                    } else {
                        reject(response.data.error);
                    }
                }
                reject(response);
            });
        });

        const promise_states = new Promise((resolve, reject) => {
            return apiRequest("get", "/common/states/IN").then((response) => {
                if (response && response.data) {
                    if (response.data.success) {
                        const responseStates = response.data.states;
                        const states = [];
                        responseStates.forEach((state) => {
                            states.push({ id: state.isoCode, label: state.name });
                        });
                        setStates(states);
                        resolve(true);
                    } else {
                        reject(response.data.error);
                    }
                }
                reject(response);
            });
        });

        Promise.allSettled([promise_type, promise_services, promise_specialities, promise_states]).then((response) => {
            response.forEach((response_status) => {
                if (response_status.status === "rejected") {
                    apiErrorHandler(response_status.reason);
                }
            });
            setShow(false);
        });
    }, [apiErrorHandler]);

    const getCities = (selectedState) => {
        const state = states.find((state) => state.label === selectedState);
        if (state) {
            setShow(true);
            apiRequest("get", "/common/cities/IN/" + state.id).then((response) => {
                if (response && response.data) {
                    if (response.data.success) {
                        const responseCities = response.data.cities;
                        const cities = [];
                        responseCities.forEach((city) => {
                            cities.push(city.name);
                        });
                        setCities(cities);
                    } else {
                        apiErrorHandler(response.data.error);
                    }
                    setShow(false);
                }
            });
        }
    };

    const nextStep = (values) => {
        setFormData(values);
        setActive(() => active + 1);
    };

    const prevStep = () => {
        setActive((current) => (current > 0 ? current - 1 : current));
    };

    const gotoHomePage = () => {
        navigate("../");
    };

    const registerHospital = () => {
        const data = {
            ...formData,
            nature: formData.nature.id,
            services: formData.services.map((service) => service.id),
            speciality: formData.speciality.map((service) => service.id),
            state: formData.state.id,
            registration_date: dayjs(formData.registration_date).format("YYYY-MM-DD HH:mm:ss"),
            email: formData.email_01,
            type: "HOSPITAL",
        };
        apiRequest("post", "/common/register/hospital", data).then((response) => {
            if (response.data) {
                if (response.data.success) {
                    setComplete(1);
                } else {
                    apiErrorHandler(response.data.error);
                }
            }
        });
    };

    const renderObject = (object) => {
        return Object.keys(object).map((obj) => {
            return `${object[obj].type},`;
        });
    };

    return (
        <>
            {show && <Loader />}
            {complete === 0 && (
                <Box component='form' className='home__form' onSubmit={handleSubmit(onSubmit)} noValidate autoComplete='off'>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant='h5' sx={{ marginY: { xs: 2, lg: 4 }, textAlign: "center" }}>
                                Register Hospital
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Stepper activeStep={active} className='p-0'>
                                <Step label='Step 1: Basic Information' />
                                <Step label='Step 2: Hospital Information' />
                                <Step label='Step 3: Review Details' />
                            </Stepper>
                        </Grid>
                        {active === 0 && (
                            <>
                                <Grid item xs={12}>
                                    <Controller
                                        name='name'
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Please enter Name!",
                                            },
                                            minLength: {
                                                value: 3,
                                                message: "The name field must have a Min. of 3 characters.",
                                            },
                                        }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => <Input fullWidth label='Name' type='text' placeholder='Enter Name' id='name' value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name='nature'
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Please select Nature!",
                                            },
                                        }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <Autocomplete
                                                fullWidth
                                                options={natureData}
                                                onChange={(e, data) => onChange(data)}
                                                getOptionLabel={(option) => option.type}
                                                renderInput={(params) => <Input {...params} label='Nature' id='nature' value={value} placeholder='Nature' error={!!error} helperText={error ? error.message : null} />}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Controller
                                        name='registration_number'
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Please enter Registration Number!",
                                            },
                                            minLength: {
                                                value: 3,
                                                message: "The name field must have a Min. of 3 characters.",
                                            },
                                        }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <Input fullWidth label='Registration Number' type='text' placeholder='Registration Number' id='registration_number' value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Controller
                                        name='registration_date'
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Please enter Registration Date!",
                                            },
                                        }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <LocalizationProvider fullWidth dateAdapter={AdapterDayjs} adapterLocale='en-in'>
                                                <DatePicker fullWidth label='Registration Date' sx={{ width: "100%" }} type='text' placeholder='Registration Date' id='registration_date' value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />
                                            </LocalizationProvider>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name='services'
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Please select Services!",
                                            },
                                        }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <Autocomplete
                                                fullWidth
                                                multiple
                                                getOptionLabel={(option) => option.type}
                                                options={servicesData}
                                                onChange={(e, data) => onChange(data)}
                                                renderInput={(params) => <Input {...params} label='Services' id='services' value={value} placeholder='Services' error={!!error} helperText={error ? error.message : null} />}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name='services_other'
                                        control={control}
                                        rules={{
                                            minLength: {
                                                value: 3,
                                                message: "Services cannot be less than 3 characters",
                                            },
                                        }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <Input fullWidth label='Services Other' type='text' placeholder='Services Other' id='services_other' value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name='speciality'
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Please select Specialty!",
                                            },
                                        }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <Autocomplete
                                                fullWidth
                                                multiple
                                                getOptionLabel={(option) => option.type}
                                                options={specialityData}
                                                onChange={(e, data) => onChange(data)}
                                                renderInput={(params) => <Input {...params} label='Speciality' id='speciality' value={value} placeholder='Speciality' error={!!error} helperText={error ? error.message : null} />}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name='speciality_other'
                                        control={control}
                                        rules={{
                                            minLength: {
                                                value: 3,
                                                message: "Specialities cannot be less than 3 characters",
                                            },
                                        }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <Input fullWidth label='Specialty Other' type='text' placeholder='Specialty Other' id='speciality_other' value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Controller
                                        name='nurses'
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Please enter Nurses!",
                                            },
                                            min: {
                                                value: 0,
                                                message: "Nurses cannot be less than 0",
                                            },
                                        }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => <Input fullWidth label='Nurses' type='number' min={0} placeholder='Nurses' id='nurses' value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Controller
                                        name='doctors'
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Please enter Doctors!",
                                            },
                                            min: {
                                                value: 0,
                                                message: "Doctors cannot be less than 0",
                                            },
                                        }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => <Input fullWidth label='Doctors' type='number' min={0} placeholder='Doctors' id='doctors' value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />}
                                    />
                                </Grid>
                            </>
                        )}

                        {active === 1 && (
                            <>
                                <Grid item xs={12}>
                                    <Controller
                                        name='address1'
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Please enter Address!",
                                            },
                                            minLength: {
                                                value: 10,
                                                message: "Address cannot be less than 10 characters",
                                            },
                                        }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => <Input fullWidth label='Address' type='text' placeholder='Address' id='address1' value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name='address2'
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => <Input fullWidth label='Address' type='text' placeholder='Address' id='address2' value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Controller
                                        name='state'
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Please select State!",
                                            },
                                        }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <Autocomplete
                                                fullWidth
                                                getOptionLabel={(option) => option.label}
                                                options={states}
                                                onChange={(e, data) => {
                                                    getCities(e.target.innerText);
                                                    onChange(data);
                                                }}
                                                renderInput={(params) => <Input {...params} label='State' id='state' value={value} placeholder='State' error={!!error} helperText={error ? error.message : null} />}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Controller
                                        name='city'
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Please select City!",
                                            },
                                        }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <Autocomplete
                                                fullWidth
                                                getOptionLabel={(option) => option}
                                                options={cities}
                                                onChange={(e, data) => onChange(data)}
                                                renderInput={(params) => <Input {...params} label='City' id='city' value={value} placeholder='City' error={!!error} helperText={error ? error.message : null} />}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Controller
                                        name='pin_code'
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Please enter PinCode!",
                                            },
                                            minLength: {
                                                value: 6,
                                                message: "Name cannot be less than 6 characters",
                                            },
                                        }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => <Input fullWidth label='Pin Code' type='text' placeholder='Pin Code' id='pin_code' value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name='email_01'
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Please enter Email!",
                                            },
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: "Please enter a valid Email!",
                                            },
                                        }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => <Input fullWidth label='Email' type='email' placeholder='Email' id='email_01' value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name='email_02'
                                        control={control}
                                        rules={{
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: "Please enter a valid Email!",
                                            },
                                        }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => <Input fullWidth label='Alt Email' type='email' placeholder='Alt Email' id='email_02' value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name='phone_01'
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Please enter Phone Number!",
                                            },
                                            minLength: {
                                                value: 10,
                                                message: "Phone Number cannot be less than 10 characters (include country and state code)",
                                            },
                                            pattern: {
                                                value: /^\d+$/,
                                                message: "Phone number must contain only numbers",
                                            },
                                        }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => <Input fullWidth label='Phone' type='text' placeholder='Phone' id='phone_01' value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name='phone_02'
                                        control={control}
                                        rules={{
                                            minLength: {
                                                value: 10,
                                                message: "Phone Number cannot be less than 10 characters (include country and state code)",
                                            },
                                        }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => <Input fullWidth label='Alt Phone' type='text' placeholder='Alt Phone' id='phone_02' value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name='mobile_01'
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Please enter Mobile Number!",
                                            },
                                            minLength: {
                                                value: 10,
                                                message: "Mobile Number cannot be less than 10 characters",
                                            },
                                        }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => <Input fullWidth label='Mobile' type='text' placeholder='Mobile' id='mobile_01' value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name='mobile_02'
                                        control={control}
                                        rules={{
                                            minLength: {
                                                value: 10,
                                                message: "Mobile Number cannot be less than 10 characters",
                                            },
                                        }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => <Input fullWidth label='Alt Mobile' type='text' placeholder='Alt Mobile' id='mobile_02' value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Controller
                                        name='total_beds'
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Please enter Total Beds!",
                                            },
                                            min: {
                                                value: 0,
                                                message: "Total Beds cannot be less than 0",
                                            },
                                        }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <Input fullWidth label='Total Beds' type='number' min={0} placeholder='Total Beds' id='total_beds' value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Controller
                                        name='icu_beds'
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Please enter ICU Beds!",
                                            },
                                            min: {
                                                value: 0,
                                                message: "ICU Beds cannot be less than 0",
                                            },
                                        }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => <Input fullWidth label='ICU Beds' type='number' min={0} placeholder='ICU Beds' id='icu_beds' value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Controller
                                        name='private_beds'
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Please enter Private Beds!",
                                            },
                                            min: {
                                                value: 0,
                                                message: "Private Beds cannot be less than 0",
                                            },
                                        }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <Input fullWidth label='Private Beds' type='number' min={0} placeholder='Private Beds' id='private_beds' value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Controller
                                        name='semi_private_beds'
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Please enter Semi Private Beds!",
                                            },
                                            min: {
                                                value: 0,
                                                message: "Semi Private Beds cannot be less than 0",
                                            },
                                        }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <Input fullWidth label='Semi Private Beds' type='number' min={0} placeholder='Semi Private Beds' id='semi_private_beds' value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Controller
                                        name='general_beds'
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Please enter General Beds!",
                                            },
                                            min: {
                                                value: 0,
                                                message: "General Beds cannot be less than 0",
                                            },
                                        }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <Input fullWidth label='General Beds' type='number' min={0} placeholder='General Beds' id='general_beds' value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />
                                        )}
                                    />
                                </Grid>
                            </>
                        )}

                        {active === 2 && (
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Typography variant='caption' display='block' gutterBottom>
                                        Hospital Name
                                    </Typography>
                                    <div className='border rounded p-2'>{formData.name}</div>
                                </Grid>

                                <Grid item xs={6}>
                                    <Typography variant='caption' display='block' gutterBottom>
                                        Nature
                                    </Typography>
                                    <div className='border rounded p-2'>{formData.nature.label}</div>
                                </Grid>

                                <Grid item xs={6}>
                                    <Typography variant='caption' display='block' gutterBottom>
                                        Registration Number
                                    </Typography>
                                    <div className='border rounded p-2'>{formData.registration_number}</div>
                                </Grid>

                                <Grid item xs={6}>
                                    <Typography variant='caption' display='block' gutterBottom>
                                        Registration Date
                                    </Typography>
                                    <div className='border rounded p-2'>{dayjs(formData.registration_date).format("DD-MM-YYYY")}</div>
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography variant='caption' display='block' gutterBottom>
                                        Services
                                    </Typography>
                                    <div className='border rounded p-2'>{renderObject(formData.services)}</div>
                                </Grid>

                                {formData.services_other && (
                                    <Grid item xs={12}>
                                        <Typography variant='caption' display='block' gutterBottom>
                                            Services Other
                                        </Typography>
                                        <div className='border rounded p-2'>{formData.services_other}</div>
                                    </Grid>
                                )}

                                <Grid item xs={12}>
                                    <Typography variant='caption' display='block' gutterBottom>
                                        Speciality
                                    </Typography>
                                    <div className='border rounded p-2'>{renderObject(formData.speciality)}</div>
                                </Grid>

                                {formData.speciality_other && (
                                    <Grid item xs={12}>
                                        <Typography variant='caption' display='block' gutterBottom>
                                            Speciality Other
                                        </Typography>
                                        <div className='border rounded p-2'>{formData.speciality_other}</div>
                                    </Grid>
                                )}

                                <Grid item xs={6}>
                                    <Typography variant='caption' display='block' gutterBottom>
                                        Nurses
                                    </Typography>
                                    <div className='border rounded p-2'>{formData.nurses}</div>
                                </Grid>

                                <Grid item xs={6}>
                                    <Typography variant='caption' display='block' gutterBottom>
                                        Doctors
                                    </Typography>
                                    <div className='border rounded p-2'>{formData.doctors}</div>
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography variant='caption' display='block' gutterBottom>
                                        Address
                                    </Typography>
                                    <div className='border rounded p-2'>{formData.address1}</div>
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography variant='caption' display='block' gutterBottom>
                                        Address 2
                                    </Typography>
                                    <div className='border rounded p-2'>{formData.address2}</div>
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography variant='caption' display='block' gutterBottom>
                                        State
                                    </Typography>
                                    <div className='border rounded p-2'>{formData.state.label}</div>
                                </Grid>

                                <Grid item xs={6}>
                                    <Typography variant='caption' display='block' gutterBottom>
                                        City
                                    </Typography>
                                    <div className='border rounded p-2'>{formData.city}</div>
                                </Grid>

                                <Grid item xs={6}>
                                    <Typography variant='caption' display='block' gutterBottom>
                                        PinCode
                                    </Typography>
                                    <div className='border rounded p-2'>{formData.pin_code}</div>
                                </Grid>

                                <Grid item xs={6}>
                                    <Typography variant='caption' display='block' gutterBottom>
                                        Email
                                    </Typography>
                                    <div className='border rounded p-2'>{formData.email_01}</div>
                                </Grid>

                                <Grid item xs={6}>
                                    <Typography variant='caption' display='block' gutterBottom>
                                        Alt Email
                                    </Typography>
                                    <div className='border rounded p-2'>{formData.email_02}</div>
                                </Grid>

                                <Grid item xs={6}>
                                    <Typography variant='caption' display='block' gutterBottom>
                                        Mobile
                                    </Typography>
                                    <div className='border rounded p-2'>{formData.mobile_01}</div>
                                </Grid>

                                <Grid item xs={6}>
                                    <Typography variant='caption' display='block' gutterBottom>
                                        Alt Mobile
                                    </Typography>
                                    <div className='border rounded p-2'>{formData.mobile_02}</div>
                                </Grid>

                                <Grid item xs={6}>
                                    <Typography variant='caption' display='block' gutterBottom>
                                        Phone
                                    </Typography>
                                    <div className='border rounded p-2'>{formData.phone_01}</div>
                                </Grid>

                                <Grid item xs={6}>
                                    <Typography variant='caption' display='block' gutterBottom>
                                        Alt Phone
                                    </Typography>
                                    <div className='border rounded p-2'>{formData.phone_02}</div>
                                </Grid>

                                <Grid item xs={6}>
                                    <Typography variant='caption' display='block' gutterBottom>
                                        Total Beds
                                    </Typography>
                                    <div className='border rounded p-2'>{formData.total_beds}</div>
                                </Grid>

                                <Grid item xs={6}>
                                    <Typography variant='caption' display='block' gutterBottom>
                                        ICU Beds
                                    </Typography>
                                    <div className='border rounded p-2'>{formData.icu_beds}</div>
                                </Grid>

                                <Grid item xs={6}>
                                    <Typography variant='caption' display='block' gutterBottom>
                                        Private Beds
                                    </Typography>
                                    <div className='border rounded p-2'>{formData.private_beds}</div>
                                </Grid>

                                <Grid item xs={6}>
                                    <Typography variant='caption' display='block' gutterBottom>
                                        Semi Private Beds
                                    </Typography>
                                    <div className='border rounded p-2'>{formData.semi_private_beds}</div>
                                </Grid>

                                <Grid item xs={6}>
                                    <Typography variant='caption' display='block' gutterBottom>
                                        General Beds
                                    </Typography>
                                    <div className='border rounded p-2'>{formData.general_beds}</div>
                                </Grid>
                            </Grid>
                        )}
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <div className='d-flex align-items-center justify-content-between mt-4'>
                                    {active > 0 && (
                                        <div>
                                            <Button variant='outlined' onClick={prevStep} className='me-2' startIcon={<ArrowBack />}>
                                                Back
                                            </Button>
                                        </div>
                                    )}
                                    {active !== 2 && (
                                        <div className='ms-auto'>
                                            <Button variant='contained' type='submit' endIcon={<ArrowForward />}>
                                                Next step
                                            </Button>
                                        </div>
                                    )}
                                    {active === 2 && (
                                        <div>
                                            <Button variant='contained' className='me-2' onClick={registerHospital}>
                                                Register Hospital
                                            </Button>
                                        </div>
                                    )}
                                </div>
                                <div className='mt-4'>
                                    <Button variant='contained' color='success' fullWidth onClick={gotoHomePage} startIcon={<ArrowBack />}>
                                        Back to Login
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            )}

            {complete === 1 && (
                <>
                    <Box
                        sx={{
                            width: 800,
                            maxWidth: "100%",
                        }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <div className='text-center'>
                                    Thank you for registration. Our team will look into data and will approve your request.
                                    <p className='mt-5'>
                                        <Link to='/'>Go to the Login Page</Link>
                                    </p>
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                </>
            )}
        </>
    );
};

export default Page;
