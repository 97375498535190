import { Autocomplete, Box, Grid, Typography } from "@mui/material";
import { Step, Stepper } from "react-form-stepper";
import Input from "@form/Input";
import Button from "@form/Button";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import apiRequest from "../utils/api";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import "dayjs/locale/en-in";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";

const Page = () => {
    const [active, setActive] = useState(0);
    const [complete, setComplete] = useState(0);
    const [hospitals, setHospitals] = useState([{ id: "other", label: "Other" }]);
    const [typeData, setTypeData] = useState([{ id: "other", label: "Other" }]);
    const [qualificationData, setQualificationData] = useState([{ id: "other", label: "Other" }]);
    const [specialityData, setSpecialityData] = useState([{ id: "other", label: "Other" }]);
    const [formData, setFormData] = useState({
        hospital: "",
        name: "",
        email: "",
        age: "",
        sex: "",
        type: "",
        registration_number: "",
        registration_validity: dayjs(),
        qualification: "",
        qualification_other: "",
        speciality: "",
        speciality_other: "",
        contact_number: "",
        status: 0,
    });
    const sexData = [
        {
            id: "m",
            label: "Male",
        },
        {
            id: "f",
            label: "Female",
        },
    ];
    const navigate = useNavigate();
    const { control, handleSubmit } = useForm({
        defaultValues: formData,
    });
    const onSubmit = async (data) => {
        nextStep(data);
    };

    useEffect(() => {
        apiRequest("get", "/common/hospital/list").then((response) => {
            response && setHospitals(response.data.data);
        });

        apiRequest("get", "/common/doctor/types").then((response) => {
            response && setTypeData(response.data.data);
        });

        apiRequest("get", "/common/doctor/educations").then((response) => {
            response && setQualificationData(response.data.data);
        });

        apiRequest("get", "/common/doctor/specialities").then((response) => {
            response && setSpecialityData(response.data.data);
        });
    }, []);

    const nextStep = (values) => {
        setFormData(values);
        if (active === 1) {
            registerHospital();
        } else {
            setActive(() => active + 1);
        }
    };

    const prevStep = () => {
        setActive((current) => (current > 0 ? current - 1 : current));
    };

    const gotoHomePage = () => {
        navigate("../");
    };

    const registerHospital = () => {
        const data = {
            ...formData,
            qualification: formData.qualification.id,
            speciality: formData.speciality.map((service) => service.id),
            registration_validity: dayjs(formData.registration_validity).format("YYYY-MM-DD HH:mm:ss"),
            type: "DOCTOR",
        };

        apiRequest("post", "/common/register/doctor/", data).then((response) => {
            response && setComplete(1);
        });
    };

    const renderObject = (object) => {
        return Object.keys(object).map((obj) => {
            return `${object[obj].type},`;
        });
    };

    return (
        <>
            {complete === 0 && (
                <Box component='form' className='home__form' onSubmit={handleSubmit(onSubmit)} noValidate autoComplete='off'>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant='h5' sx={{ marginY: { xs: 2, lg: 4 }, textAlign: "center" }}>
                                Register Hospital
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Stepper activeStep={active} className='p-0'>
                                <Step label='Step 1: Information' />
                                <Step label='Step 2: Review Details' />
                            </Stepper>
                        </Grid>
                        {active === 0 && (
                            <>
                                <Grid item xs={12}>
                                    <Controller
                                        name='hospital'
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Please select Hospital!",
                                            },
                                        }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <Autocomplete
                                                fullWidth
                                                options={hospitals}
                                                onChange={(e, data) => onChange(data)}
                                                getOptionLabel={(option) => option.name}
                                                renderInput={(params) => <Input {...params} label='Hospital' id='hospital' value={value} placeholder='Hospital' error={!!error} helperText={error ? error.message : null} />}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name='name'
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Please enter Name!",
                                            },
                                            minLength: {
                                                value: 10,
                                                message: "Name cannot be less than 10 characters",
                                            },
                                        }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => <Input fullWidth label='Name' type='text' placeholder='Enter Name' id='name' value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name='email'
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Please enter Email!",
                                            },
                                            minLength: {
                                                value: 10,
                                                message: "Email ID cannot be less than 10 characters",
                                            },
                                        }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => <Input fullWidth label='Email' type='email' placeholder='Enter Email' id='email' value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Controller
                                        name='age'
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Please enter Age!",
                                            },
                                            min: {
                                                value: 18,
                                                message: "Age cannot be less than 18",
                                            },
                                        }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => <Input fullWidth label='Age' type='text' placeholder='Age' id='age' value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Controller
                                        name='sex'
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Please select Sex!",
                                            },
                                        }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <Autocomplete
                                                fullWidth
                                                options={sexData}
                                                onChange={(e, data) => onChange(data)}
                                                getOptionLabel={(option) => option.label}
                                                renderInput={(params) => <Input {...params} label='Sex' id='sex' value={value} placeholder='Sex' error={!!error} helperText={error ? error.message : null} />}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name='type'
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Please select Nature!",
                                            },
                                        }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <Autocomplete
                                                fullWidth
                                                options={typeData}
                                                onChange={(e, data) => onChange(data)}
                                                getOptionLabel={(option) => option.type}
                                                renderInput={(params) => <Input {...params} label='Type' id='type' value={value} placeholder='Type' error={!!error} helperText={error ? error.message : null} />}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Controller
                                        name='registration_number'
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Please enter Registration Number!",
                                            },
                                            minLength: {
                                                value: 10,
                                                message: "Registration Number cannot be less than 10 characters",
                                            },
                                        }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <Input fullWidth label='Registration Number' type='text' placeholder='Registration Number' id='registration_number' value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Controller
                                        name='registration_validity'
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Please enter Registration Validity!",
                                            },
                                        }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <LocalizationProvider fullWidth dateAdapter={AdapterDayjs} adapterLocale='en-in'>
                                                <DatePicker fullWidth label='Registration Validity' sx={{ width: "100%" }} type='text' placeholder='Registration Validity' id='registration_validity' value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />
                                            </LocalizationProvider>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name='qualification'
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Please select Qualification!",
                                            },
                                        }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <Autocomplete
                                                fullWidth
                                                multiple
                                                getOptionLabel={(option) => option.type}
                                                options={qualificationData}
                                                onChange={(e, data) => onChange(data)}
                                                renderInput={(params) => <Input {...params} label='Qualification' id='qualification' value={value} placeholder='Qualification' error={!!error} helperText={error ? error.message : null} />}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name='qualification_other'
                                        control={control}
                                        rules={{
                                            minLength: {
                                                value: 2,
                                                message: "Qualification cannot be less than 2 characters",
                                            },
                                        }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <Input fullWidth label='Qualification Other' type='text' placeholder='Qualification Other' id='qualification_other' value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name='speciality'
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Please select Specialty!",
                                            },
                                        }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <Autocomplete
                                                fullWidth
                                                multiple
                                                getOptionLabel={(option) => option.type}
                                                options={specialityData}
                                                onChange={(e, data) => onChange(data)}
                                                renderInput={(params) => <Input {...params} label='Speciality' id='speciality' value={value} placeholder='Speciality' error={!!error} helperText={error ? error.message : null} />}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name='speciality_other'
                                        control={control}
                                        rules={{
                                            minLength: {
                                                value: 3,
                                                message: "Specialities cannot be less than 3 characters",
                                            },
                                        }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <Input fullWidth label='Specialty Other' type='text' placeholder='Specialty Other' id='speciality_other' value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name='contact_number'
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Please enter Contact Number!",
                                            },
                                            minLength: {
                                                value: 7,
                                                message: "Contact Number cannot be less than 7 characters",
                                            },
                                        }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <Input fullWidth label='Emergency Contact Number for Patients' type='text' min={0} placeholder='Contact Number' id='contact_number' value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />
                                        )}
                                    />
                                </Grid>
                            </>
                        )}

                        {active === 1 && (
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant='caption' display='block' gutterBottom>
                                        Hospital Name
                                    </Typography>
                                    <div className='border rounded p-2'>{formData.hospital.label}</div>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='caption' display='block' gutterBottom>
                                        Name
                                    </Typography>
                                    <div className='border rounded p-2'>{formData.name}</div>
                                </Grid>

                                <Grid item xs={6}>
                                    <Typography variant='caption' display='block' gutterBottom>
                                        Age
                                    </Typography>
                                    <div className='border rounded p-2'>{formData.age}</div>
                                </Grid>

                                <Grid item xs={6}>
                                    <Typography variant='caption' display='block' gutterBottom>
                                        Sex
                                    </Typography>
                                    <div className='border rounded p-2'>{formData.sex.label === "m" ? "Male" : "Female"}</div>
                                </Grid>

                                <Grid item xs={6}>
                                    <Typography variant='caption' display='block' gutterBottom>
                                        Registration Number
                                    </Typography>
                                    <div className='border rounded p-2'>{formData.registration_number}</div>
                                </Grid>

                                <Grid item xs={6}>
                                    <Typography variant='caption' display='block' gutterBottom>
                                        Registration Validity
                                    </Typography>
                                    <div className='border rounded p-2'>{dayjs(formData.registration_validity).format("DD-MM-YYYY")}</div>
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography variant='caption' display='block' gutterBottom>
                                        Qualification
                                    </Typography>
                                    <div className='border rounded p-2'>{renderObject(formData.qualification)}</div>
                                </Grid>

                                {formData.qualification_other && (
                                    <Grid item xs={12}>
                                        <Typography variant='caption' display='block' gutterBottom>
                                            Qualification Other
                                        </Typography>
                                        <div className='border rounded p-2'>{formData.qualification_other}</div>
                                    </Grid>
                                )}

                                <Grid item xs={12}>
                                    <Typography variant='caption' display='block' gutterBottom>
                                        Speciality
                                    </Typography>
                                    <div className='border rounded p-2'>{renderObject(formData.speciality)}</div>
                                </Grid>

                                {formData.speciality_other && (
                                    <Grid item xs={12}>
                                        <Typography variant='caption' display='block' gutterBottom>
                                            Speciality Other
                                        </Typography>
                                        <div className='border rounded p-2'>{formData.speciality_other}</div>
                                    </Grid>
                                )}

                                <Grid item xs={12}>
                                    <Typography variant='caption' display='block' gutterBottom>
                                        Contact Number
                                    </Typography>
                                    <div className='border rounded p-2'>{formData.contact_number}</div>
                                </Grid>
                            </Grid>
                        )}
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <div className='d-flex align-items-center justify-content-between mt-4'>
                                    {active > 0 && (
                                        <div>
                                            <Button variant='outlined' onClick={prevStep} className='me-2' startIcon={<ArrowBack />}>
                                                Back
                                            </Button>
                                        </div>
                                    )}
                                    {active !== 2 && (
                                        <div className='ms-auto'>
                                            <Button variant='contained' type='submit' endIcon={<ArrowForward />}>
                                                Next step
                                            </Button>
                                        </div>
                                    )}
                                    {active === 2 && (
                                        <div>
                                            <Button variant='contained' className='me-2' onClick={registerHospital}>
                                                Register Hospital
                                            </Button>
                                        </div>
                                    )}
                                </div>
                                <div className='mt-4'>
                                    <Button variant='contained' color='success' fullWidth onClick={gotoHomePage} startIcon={<ArrowBack />}>
                                        Back to Login
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            )}

            {complete === 1 && (
                <>
                    <Box
                        sx={{
                            width: 800,
                            maxWidth: "100%",
                        }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <div className='text-center'>
                                    Thank you for registration. Our team will look into data and will approve your request.
                                    <p className='mt-5'>
                                        <Link to='/'>Go to the Login Page</Link>
                                    </p>
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                </>
            )}
        </>
    );
};

export default Page;
