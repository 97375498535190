import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import DataContext from "../context/context";

const ProtectedAdmin = ({ children }) => {
    const { user } = useContext(DataContext);

    if (user.logged && user.type === "ADMIN") {
        return children ? children : <Outlet />;
    }

    return <Navigate to='/' />;
};

export default ProtectedAdmin;
