import axios from "axios";

const axiosInstance = axios.create({
    // baseURL: process.env.NODE_ENV === "development" ? "http://localhost:3001" : "https://dev-middleware.svasthacircle.com",
    baseURL: "https://dev-middleware.svasthacircle.com",
    timeout: 6000,
    headers: {
        "Content-Type": "application/json",
    },
});

// wrapper function to handle all methods
const apiRequest = (method, url, data) => {
    return axiosInstance.request({
        method,
        url,
        data,
    });
};

// Error handler default function
let error_handler = (message) => console.log(message);

// get client IP address
apiRequest("get", "https://ipapi.co/json/").then((response) => {
    sessionStorage.setItem("ip_address", response.data.ip);
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
    function (config) {
        // Do something before request is sent
        const ip_address = sessionStorage.getItem("ip_address");
        if (config.data) {
            if (config.data instanceof FormData) {
                config.data.append("ip_address", ip_address);
            } else {
                const data = JSON.parse(JSON.stringify(config.data));
                if (typeof data === "string") {
                    config.data += "&ip_address=" + ip_address;
                } else {
                    config.data["ip_address"] = ip_address;
                }
            }
        } else {
            const data = {};
            data["ip_address"] = ip_address;
            config.data = data;
        }

        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
    (response) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    },
    (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        const message = error.response?.data.error || error.message;
        error_handler(message);
        return false;
    }
);

// set Token for APIs
export const setToken = (token) => {
    axiosInstance.defaults.headers.common["Authorization"] = token;
};

// set Error Handler for API error;
export const setErrorHandler = (handler) => {
    error_handler = handler;
};

export default apiRequest;
