import { Backdrop, CircularProgress } from "@mui/material";

function Loader() {
    return (
        <Backdrop sx={{ color: "#000", zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
            <CircularProgress color='warning' />
        </Backdrop>
    );
}

export default Loader;
