import { createBrowserRouter } from "react-router-dom";
import Pages from "@pages";
import Loader from "@components/Loader";
import NoMatch from "@components/NoMatch";
import ProtectedAdmin from "./protectedAdmin";
import ProtectedDoctor from "./protectedDoctor";
import ProtectedHospital from "./protectedHospital";
import { Suspense } from "react";

const routes = createBrowserRouter([
    {
        path: "/",
        element: <Pages.Home />,
        children: [
            {
                index: true,
                element: <Pages.Login />,
            },
            {
                path: "register-hospital",
                element: <Pages.RegisterHospital />,
            },
            {
                path: "register-doctor",
                element: <Pages.RegisterDoctor />,
            },
            {
                path: "logout",
                element: <Pages.Logout />,
            },
            {
                path: "*",
                element: <NoMatch />,
            },
        ],
    },
    {
        path: "/admin",
        element: (
            <Suspense fallback={<Loader />}>
                <ProtectedAdmin>
                    <Pages.Admin.Dashboard />
                </ProtectedAdmin>
            </Suspense>
        ),
        children: [
            {
                index: true,
                element: <Pages.Admin.Home />,
            },
            {
                path: "hospitals",
                element: <Pages.Admin.Hospitals />,
            },
            {
                path: "hospitals-branches",
                element: <Pages.Admin.Branches />,
            },
            {
                path: "hospitals-types",
                element: <Pages.Admin.Types />,
            },
            {
                path: "hospitals-specialities",
                element: <Pages.Admin.Specialities />,
            },
            {
                path: "hospitals-services",
                element: <Pages.Admin.Services />,
            },
            {
                path: "hospitals-register",
                element: <Pages.Admin.Home />,
            },
            {
                path: "doctors",
                element: <Pages.Admin.Home />,
            },
            {
                path: "doctors-educations",
                element: <Pages.Admin.Home />,
            },
            {
                path: "doctors-types",
                element: <Pages.Admin.Home />,
            },
            {
                path: "doctors-specialities",
                element: <Pages.Admin.Home />,
            },
            {
                path: "doctors-register",
                element: <Pages.Admin.Home />,
            },
            {
                path: "*",
                element: <NoMatch />,
            },
        ],
    },
    {
        path: "/hospital",
        element: (
            <Suspense fallback={<Loader />}>
                <ProtectedHospital>
                    <Pages.Hospital.Dashboard />
                </ProtectedHospital>
            </Suspense>
        ),
        children: [
            {
                index: true,
                element: <Pages.Hospital.Home />,
            },
            {
                path: "hospitals",
                element: <Pages.Hospital.Home />,
            },
            {
                path: "hospitals-branches",
                element: <Pages.Hospital.Home />,
            },
            {
                path: "hospitals-types",
                element: <Pages.Hospital.Home />,
            },
            {
                path: "hospitals-specialities",
                element: <Pages.Hospital.Home />,
            },
            {
                path: "hospitals-services",
                element: <Pages.Hospital.Home />,
            },
            {
                path: "hospitals-register",
                element: <Pages.Hospital.Home />,
            },
            {
                path: "doctors",
                element: <Pages.Hospital.Home />,
            },
            {
                path: "doctors-educations",
                element: <Pages.Hospital.Home />,
            },
            {
                path: "doctors-types",
                element: <Pages.Hospital.Home />,
            },
            {
                path: "doctors-specialities",
                element: <Pages.Hospital.Home />,
            },
            {
                path: "doctors-register",
                element: <Pages.Hospital.Home />,
            },
            {
                path: "*",
                element: <NoMatch />,
            },
        ],
    },
    {
        path: "/doctor",
        element: (
            <Suspense fallback={<Loader />}>
                <ProtectedDoctor>
                    <Pages.Doctor.Dashboard />
                </ProtectedDoctor>
            </Suspense>
        ),
        children: [
            {
                index: true,
                element: <Pages.Doctor.Home />,
            },
            {
                path: "register-hospital",
                element: <Pages.RegisterHospital />,
            },
            {
                path: "register-doctor",
                element: <Pages.RegisterDoctor />,
            },
            {
                path: "*",
                element: <NoMatch />,
            },
        ],
    },
    {
        path: "*",
        element: (
            <Suspense fallback={<Loader />}>
                <NoMatch />
            </Suspense>
        ),
    },
]);

export default routes;
